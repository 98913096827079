import React from "react"
import TableStyle from "../styles/table.module.scss"

const Table = ({ data }) => {
  return (
    <div className={TableStyle.table}>
      {data.map((row, i) => (
        <div key={i} className={TableStyle.row}>
          <div className={`${TableStyle.cell} ${TableStyle.title}`}>
            {row.title}
          </div>
          <div className={TableStyle.cell}>{row.details}</div>
        </div>
      ))}
    </div>
  )
}

export default Table
