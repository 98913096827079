import React from "react"

import Styles from "../styles/button.module.scss"

const UrlButton = ({ url, text }) => {
  const domain = url.substring(0, url.indexOf("/"))

  return (
    <a
      className={Styles.button}
      href={url.includes("http") ? "/" : "https://" + url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text || domain || url}
    </a>
  )
}

export default UrlButton
