import React from "react"
import Fade from "react-reveal/Fade"

import ExperienceItem from "./experienceItem"
import Styles from "../styles/experienceTable.module.scss"

const ExperienceTimeline = ({ experienceData }) => {
  const sortedExperiences = experienceData.edges.sort(
    (a, b) => new Date(b.node.periodStart) - new Date(a.node.periodStart)
  )

  return (
    <div className={Styles.table}>
      {sortedExperiences.map((entry, i) => (
        <Fade key={i} delay={100}>
          <ExperienceItem data={entry} />
        </Fade>
      ))}
    </div>
  )
}

export default ExperienceTimeline
