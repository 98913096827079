import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Styles from "../styles/experienceTable.module.scss"
import moment from "moment"
import UrlButton from "./urlButton"
import Skill from "./skill"

const ExperienceItem = ({ data }) => {
  const {
    role,
    company,
    periodStart,
    periodEnd,
    url,
    description,
    tech,
  } = data.node

  return (
    <div className={Styles.container}>
      <div className={Styles.rightSide}>
        <div className={Styles.roleCompany}>
          {role} @ <span className={Styles.company}>{company}</span>
        </div>
        <div className={Styles.dates}>
          {moment(periodStart).format("MMM YYYY")} -{" "}
          {periodEnd ? moment(periodEnd).format("MMM YYYY") : "present"}
        </div>
        {url && (
          <div className={Styles.linksContainer}>
            {url.map((url, i) => (
              <UrlButton key={i} url={url} />
            ))}
          </div>
        )}
      </div>
      <div className={Styles.separator}></div>
      <div className={Styles.leftSide}>
        <div className={Styles.description}>
          {documentToReactComponents(description.json)}
        </div>
        <div className={Styles.techContainer}>
          {tech.map((skill, i) => (
            <Skill key={i} skill={skill} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ExperienceItem
