import React from "react"

import headersStyle from "../styles/headers.module.scss"

const SubHeader = ({ emoji, children, className }) => (
  <div
    className={`${headersStyle.subHeaderConainer} ${
      className ? className : ""
    } `}
  >
    {emoji ? <span className={headersStyle.headerEmoji}>{emoji}</span> : null}
    <h2 className={headersStyle.subHeader}>{children}</h2>
  </div>
)

export default SubHeader
