import React from "react"

import Styles from "../styles/skill.module.scss"

const Skill = ({ skill }) => {
  return (
    <div className={`${Styles.skill} ${Styles[skill.type]}`}>{skill.name}</div>
  )
}

export default Skill
