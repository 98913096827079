import React from "react"
import _ from "lodash"
import { useStaticQuery, graphql } from "gatsby"

import Styles from "../styles/skillLegend.module.scss"

const SkillLegend = () => {
  const { allContentfulSkill } = useStaticQuery(graphql`
    query {
      allContentfulSkill {
        edges {
          node {
            name
            type
            category
            id
          }
        }
      }
    }
  `)

  const skills = allContentfulSkill.edges.map(edge => edge.node)
  const skillsTypes = _.uniq(skills.map(skill => skill.type))

  return (
    <div className={Styles.skillTypesContainer}>
      {skillsTypes.map((type, i) => (
        <div key={i} className={`${Styles.skillTypeContainer} ${Styles[type]}`}>
          <div className={Styles.shape}></div>
          {type}
        </div>
      ))}
    </div>
  )
}

export default SkillLegend
