import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageSection from "../components/pageSection"
import SubHeader from "../components/subHeader"
import ExperienceTimeline from "../components/experienceTimeline"
import SkillLegend from "../components/skillLegend"
import Table from "../components/table"

const WorkPage = () => {
  const {
    contentfulWorkPage,
    contentfulAboutPage: { educationTimeline },
    allContentfulExperienceItem: experienceData,
  } = useStaticQuery(graphql`
    query {
      contentfulWorkPage {
        title
        description {
          description
        }
      }

      contentfulAboutPage {
        educationTimeline {
          title
          details
        }
      }

      allContentfulExperienceItem {
        edges {
          node {
            company
            role
            periodStart
            periodEnd
            url
            description {
              description
              json
            }
            tech {
              name
              type
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title={contentfulWorkPage.title} />
      <PageSection first>
        <SubHeader emoji={"🧑🏻‍🚀"}>Experience</SubHeader>
        <ExperienceTimeline experienceData={experienceData} />
        <SkillLegend />
      </PageSection>

      <PageSection>
        <SubHeader emoji={"🧑🏻‍🏫"}>Education</SubHeader>
        <div>
          {/* <span>{educationText.educationText}</span> */}
          <Table data={educationTimeline.reverse()} />
        </div>
      </PageSection>
    </Layout>
  )
}

export default WorkPage
